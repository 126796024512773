<template>
  <div
    v-if="getElementData"
    :class="title.toLowerCase()">
    {{ information }}
  </div>
</template>
<script>
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'ElementContainer',
  props: {
    element: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    getElementData() {
      const { element, item } = this;
      const { DESCRIPTION, TYPE_DESIGNATION } = productHeaders;
      if (item[element] && item[element] !== '\b' && item[element] !== ' ') {
        return {
          title: element,
          component: item?.[element],
        };
      }
      return {
        title: element,
        component: [DESCRIPTION, TYPE_DESIGNATION].includes(element) ? '' : '-',
      };
    },
    information() {
      if (this.getElementData.component?.length > 80) {
        return `${this.getElementData.component.substr(0, 80)}...`;
      }
      return Array.isArray(this.getElementData.component) ? this.getElementData.component.join(', ') : this.getElementData.component;
    },
    title() {
      return this.getElementData.title;
    },
  },
};
</script>
<style lang="scss" scoped>
.type {
  color: var(--v-green-base);
  font-size: 20px;
  font-weight: 700;
}
.manufacturer {
  color: var(--v-blue-base);
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.model {
  color: var(--v-black-base);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
</style>